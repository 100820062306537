





import { Component, Vue, Prop } from "vue-property-decorator";
import List from "./list.vue";
@Component({
  components: {
    List,
  },
})
export default class Name extends Vue {
  private type: any = "list";
}
